.banner-image{
    width: 100%;
    height: 98vh;
    display: block;
    background-size: cover;
    background-position: center center
}

.banner-one{
    background-image:url(../../assets/images/banner1.jpg);
}
.banner-two{
    background-image:url(../../assets/images/banner2.jpg);
}
.banner-three{
    background-image:url(../../assets/images/banner3.jpg);
}
.banner-four{
    background-image:url(../../assets/images/banner4.jpg);
}

.carousel-caption{
    top: 50%;
    bottom: auto;
    text-shadow: 2px 2px 5px rgba(0,0,0,0.52);
}