

.NavBar .nav-link {
    font-size: 1rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 500;
    transition: none;
    color: #aaaaaa;
  }
  
  .NavBar.navbar {
    border-bottom: 0;
    letter-spacing: 1px;
    min-height: 50px;
    margin-bottom: 0;
    background-color: #ffffff;
    border-bottom: solid 1px transparent;
    transition: all .3s ease-in-out;
    font-weight: 400;
    padding: 0;
  }

  
.NavBar.collapsed.navbar {
    padding: 0;
    background-color: #ffffff;
    border-bottom: solid 1px #ffffff;
  }
  
  .NavBar .navbar-toggler,
  .NavBar .navbar-toggler:active,
  .NavBar .navbar-toggler:focus {
    border: none;
    outline: none;
  }
  
  .navbar .navbar-brand img {
    -webkit-backface-visibility: hidden;
    transition: all .25s ease-in-out;
    height: 85px;
  }
  
  .NavBar.collapsed .navbar-brand img {
    height: 35px;
  }
  
  .NavBar .navbar-dark .navbar-toggler {
    border: 0;
  }


  
@media (max-width: 767px) {
    .NavBar.navbar {
      border-bottom: solid 1px #ffffff;
      background-color: #ffffff;
    }
  
    .NavBar .navbar-brand img {
      height: 35px;
      margin: 2px 16px;
    }
  
    .NavBar .navbar-collapse {
      padding: 4px 0;
      border-top: solid 1px #ffffff;
    }
  }
  
  @media (min-width: 768px) {
    .NavBar .navbar-brand img {
      height: 75px;

    }
  
    .NavBar.navbar-expand-md .navbar-nav .nav-link {
      padding-right: 1rem;
      padding-left: 1rem;
    }
  
    .NavBar .dropdown-item {
      padding: .25rem 1rem;
    }
  }
  